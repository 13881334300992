import TicketCategoryItem from "./Item";

const TicketCategoriesList = ({ items }) => {
  return (
    <>
      {items.map((category) => (
        <TicketCategoryItem key={category._id.toString()} category={category} />
      ))}
    </>
  );
};

export default TicketCategoriesList;
