import { useState, useEffect } from "react";
import { redirect, useLoaderData, useLocation } from "react-router-dom";

import { MobileView, BrowserView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";

import { RiServiceLine } from "react-icons/ri";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";

import ServicePlansList from "../../components/ServicePlan/List";
import ServicePlanFilter from "../../components/ServicePlan/Filter";

import ListWrapper from "../../UI/ListWrapper";

import useServicePlanFilterStore from "../../store/filters/service-plans";
import useSidebarStore from "../../store/sidebar";

const ServicePlans = () => {
  const location = useLocation();
  const { setLeftSidebarContent } = useSidebarStore();
  const { servicePlans } = useLoaderData();
  const filterStore = useServicePlanFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetch();
  }, [location]);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <ServicePlanFilter items={servicePlans} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, servicePlans]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <RiServiceLine /> Услуги
      </>
    );
  };

  return (
    <ListWrapper
      title={title}
      filteredList={filterStore.filteredList}
      filterStore={filterStore}
      onUpdatePage={filterStore.fetch}
      secondaryList={servicePlans}
      isLoading={filterStore.isLoading}
      hideLengthRow
      addRoute="/service-plans/add"
    >
      <MobileView>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
          <Offcanvas.Body>
            <ServicePlanFilter
              items={servicePlans}
              setShowOffcanvas={setShowOffcanvas}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="justify-content-between text-secondary my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row className="justify-content-between text-secondary my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
        </Row>
      </BrowserView>
      <ServicePlansList items={filterStore.filteredList}></ServicePlansList>
    </ListWrapper>
  );
};

export default ServicePlans;

export async function loader() {
  document.title = "УСЛУГИ";

  const { token } = getLocalStorageData();

  const servicePlansResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/service-plans`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!servicePlansResponse.ok) {
    throw servicePlansResponse;
  }

  return {
    servicePlans: await servicePlansResponse.json(),
  };
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const intent = data.get("intent");
  const id = data.get("id");

  if (intent === "delete") {
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/service-plans/delete/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      },
    );

    if ([409].includes(response.status)) {
      return response;
    }

    if (!response.ok) {
      throw response;
    }

    return redirect("/service-plans");
  }
}
