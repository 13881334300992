import { create } from "zustand";

import { getLocalStorageData } from "../../util/auth";

const userFilter = (state) => {
  const originalList = state.originalList ? state.originalList : [];
  return originalList.filter((item) => {
    if (state.searchTerm.length > 0) {
      return [
        item.email,
        item.phone,
        `${item.firstName} ${item.lastName}`,
        item.firstName,
        item.lastName,
        item.position,
        item.role,
        ...item.categories?.flatMap((category) => [category.title]),
        ...item.responsibleForCompanies?.flatMap((company) => [company.alias]),
        item.company?.alias,
      ]
        .join(" ")
        .toLowerCase()
        .includes(state.searchTerm);
    } else {
      return true;
    }
  });
};

const searchItems = (query, items) => {
  if (!query) return items;

  // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
  const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

  return items.filter((item) => {
    const fieldsToSearch = [
      item.email,
      item.phone,
      `${item.firstName} ${item.lastName}`,
      item.firstName,
      item.lastName,
      item.position,
      item.role,
      JSON.stringify(item.categories),
      JSON.stringify(item.responsibleForCompanies),
      item.company?.alias,
    ];

    return queryTerms.every((term) =>
      fieldsToSearch.some(
        (field) => field && field.toLowerCase().includes(term),
      ),
    );
  });
};

const useUserFilterStore = create((set) => ({
  isAdmin: false,
  isServiceAccount: false,
  isCloudTelephony: false,
  permissions: [],
  tgBot: "any",
  isActive: "any",
  roles: [],
  categories: [],
  company: [],
  respForCompanies: [],
  searchTerm: "",
  originalList: [],
  filteredList: [],
  fullTextSearch: (query) =>
    set((state) => ({ filteredList: searchItems(query, userFilter(state)) })),
  isLoading: false,
  fetch: async () => {
    set({ isLoading: true });
    const { token } = getLocalStorageData();
    const response = await fetch(`${process.env.REACT_APP_ADDRESS}/api/users`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const data = await response.json();
    set({
      originalList: data.users,
      isLoading: false,
    });
  },
  applyFilter: () => set((state) => ({ filteredList: userFilter(state) })),
  resetFilter: () => {
    set(() => ({
      isAdmin: false,
      isServiceAccount: false,
      isCloudTelephony: false,
      permissions: [],
      tgBot: "any",
      isActive: "any",
      roles: [],
      categories: [],
      company: [],
      respForCompanies: [],
      searchTerm: "",
    }));
    set((state) => ({
      filteredList: userFilter(state),
    }));
  },
}));

export default useUserFilterStore;
