import ServicePlanItem from "./Item";

const ServicePlansList = ({ items }) => {
  return (
    <>
      {items.map((servicePlan) => (
        <ServicePlanItem
          key={servicePlan._id.toString()}
          servicePlan={servicePlan}
        />
      ))}
    </>
  );
};

export default ServicePlansList;
