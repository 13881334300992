import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useHttp from "../../hooks/use-http";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";

import { toastActions } from "../../store/toast";

import { RiDeleteBinLine } from "react-icons/ri";

import { AuthedUserContext } from "../../store/authed-user-context";
import { getLocalStorageData } from "../../util/auth";

const DeleteTicket = (props) => {
  const navigate = useNavigate();

  const { token } = getLocalStorageData();
  const { permissions } = useContext(AuthedUserContext);
  const { canDeleteTickets } = permissions;

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { sendRequest: deleteTicketHandler } = useHttp();

  const submitHandler = (event) => {
    event.preventDefault();

    const ticket = {
      _id: props.ticket._id,
    };

    const deleteTicket = (data) => {
      if (data.message === "Ticket deleted successfully!") {
        dispatch(
          toastActions.setState({
            variant: "warning text-white",
            message: "Заявка удалёна :(",
            show: true,
          }),
        );
        navigate("/tickets");
      } else {
        dispatch(
          toastActions.setState({
            variant: "danger text-white",
            message: data.message,
            show: true,
          }),
        );
      }
    };

    deleteTicketHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/tickets/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: ticket,
      },
      deleteTicket,
    );
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>
        <span style={{ color: "red" }}>
          <RiDeleteBinLine /> Удалить
        </span>
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.ticket.alias}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Вы уверены? Это действие нельзя отменить.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button variant="danger" onClick={submitHandler}>
            <RiDeleteBinLine /> Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteTicket;
