import { useLoaderData, redirect } from "react-router-dom";

import { getLocalStorageData } from "../../util/auth";

import ViewCompany from "../../components/Company/View";

const ViewCompanyPage = () => {
  const { company, servicePlans, servicePlansList } = useLoaderData();
  return (
    <ViewCompany
      company={company}
      servicePlans={servicePlans}
      servicePlansList={servicePlansList}
    />
  );
};

export default ViewCompanyPage;

export async function loader({ params }) {
  const { token, isAdmin } = getLocalStorageData();

  const companyResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/companies/${params.id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!companyResponse.ok) {
    throw companyResponse;
  }

  const companyData = await companyResponse.json();

  let servicePlansResponse = [];
  if (isAdmin) {
    servicePlansResponse = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/service-plans/`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );

    if (!servicePlansResponse.ok) {
      throw servicePlansResponse;
    }
  }

  const servicePlansData = isAdmin ? await servicePlansResponse.json() : [];

  document.title = `${companyData?.company.alias}`;

  return {
    company: companyData.company,
    servicePlans: companyData.servicePlans,
    servicePlansList: servicePlansData,
  };
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const intent = data.get("intent");
  const id = data.get("id");

  if (intent === "addServicePlan") {
    const newServicePlan = {
      plan: data.get("servicePlan"),
      isActiveSince: data.get("isActiveSince"),
    };

    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/companies/add-service-plan/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(newServicePlan),
      },
    );

    if ([409].includes(response.status)) {
      return response;
    }

    if (!response.ok) {
      throw response;
    }

    return response;
  }

  if (intent === "deleteServicePlan") {
    const servicePlanId = data.get("servicePlanId");
    const companyId = data.get("companyId");

    console.log(companyId);

    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/companies/delete-service-plan/${companyId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ servicePlanId: servicePlanId }),
      },
    );

    if ([409].includes(response.status)) {
      return response;
    }

    if (!response.ok) {
      throw response;
    }

    return response;
  }

  if (intent === "delete") {
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/companies/delete/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      },
    );

    if ([409].includes(response.status)) {
      return response;
    }

    if (!response.ok) {
      throw response;
    }

    return redirect("/companies");
  }
}
