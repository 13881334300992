import { Link } from "react-router-dom";

import DeleteRoutineTask from "./Delete";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import ItemCard from "../../UI/ItemCard";

import { RiEdit2Line, RiBuilding2Line } from "react-icons/ri";

import useOffcanvasStore from "../../store/offcanvas";

function RoutineTaskItem({ task }) {
  const offcanvas = useOffcanvasStore();
  return (
    <ItemCard createdAt={task.createdAt}>
      <Row>
        <Col sm={9}>
          <h4>
            <Row>
              <Col sm="auto">
                <Badge className="mb-2 w-100" bg="secondary">
                  <RiBuilding2Line /> {task.company?.alias}
                </Badge>
              </Col>
              <Col sm="auto">{task.title}</Col>
            </Row>
          </h4>

          <Row>
            <Col>
              <div className="py-1">Категория: {task.category.title}</div>
              <div className="pt-1">Расписание cron: {task.cronSchedule}</div>
            </Col>
          </Row>
        </Col>
        <Col className="text-end">
          <div className="h-100 d-flex flex-column justify-content-between">
            <div className="w-100">
              <Col sm="auto">
                {!task.isActive && (
                  <Badge className="bg-danger">отключено</Badge>
                )}
              </Col>
            </div>
            <div className="row mt-1 flex-shrink-1 justify-content-end align-items-end">
              <Col sm="auto">
                <Dropdown>
                  <Dropdown.Toggle>Действия</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={`update/${task._id}`}
                      onClick={offcanvas.setShow}
                    >
                      <RiEdit2Line /> Изменить
                    </Dropdown.Item>
                    <DeleteRoutineTask routineTask={task} />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default RoutineTaskItem;
