import { NavLink } from "react-router-dom";

import { formatDate } from "../../util/format-date";

import ItemCard from "../../UI/ItemCard";

import { RiBuilding2Line } from "react-icons/ri";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { FaRegCalendar } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";
import { IoMdAttach } from "react-icons/io";

import { formatDateTime } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";
import { BrowserView, MobileView } from "react-device-detect";

function TicketItem(props) {
  const {
    num,
    company,
    title,
    attachments,
    applicant,
    realSender,
    responsibles,
    createdAt,
    deadline,
    finishedAt,
    state,
    isClosed,
    latestComment,
    scheduledWorks,
  } = props.ticket;

  const isOverdue =
    new Date(props.ticket.deadline) < new Date() && state !== "Закрыта"
      ? true
      : false;

  const badgeBg =
    props.ticket.state === "Новая"
      ? "warning"
      : props.ticket.state === "Не в работе"
        ? "warning"
        : props.ticket.state === "В работе"
          ? "info"
          : props.ticket.state === "Выполнена"
            ? "success"
            : props.ticket.state === "Закрыта"
              ? "secondary"
              : "info";

  const latestCommentPopover = (
    <Popover style={{ maxWidth: "30rem" }}>
      <Popover.Header as="h3">Последний комментарий</Popover.Header>
      <Popover.Body>
        <p>
          <strong>{`${latestComment?.createdBy.lastName} ${latestComment?.createdBy.firstName}`}</strong>{" "}
          <span
            className={
              latestComment?.attachments.length > 0
                ? "text-body-secondary"
                : "text-body-secondary mb-0"
            }
          >{`${formatDate(latestComment?.createdAt)}`}</span>
        </p>
        <p className={latestComment?.attachments ? "mb-2" : "mb-0"}>
          {latestComment?.content}
        </p>
        {latestComment?.attachments && (
          <>
            {latestComment?.attachments.map((a) => (
              <Row key={a.name}>
                <Col sm="12">
                  <a
                    href={`${process.env.REACT_APP_ADDRESS}/uploads/${a.name}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {a.name}
                  </a>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Popover.Body>
    </Popover>
  );

  const scheduledWorksPopover = (
    <Popover style={{ maxWidth: "30rem" }}>
      <Popover.Header as="h3">Запланированные работы</Popover.Header>
      <Popover.Body>
        {scheduledWorks?.map((work) => (
          <div key={work._id}>
            <Row className="mb-2">
              <Col>
                <strong>{`${work?.executor?.lastName} ${work?.executor?.firstName}`}</strong>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                {!work.visitRequired
                  ? "Запланированы удалённые работы "
                  : "Запланирован выезд "}
                на <strong>{formatDateTime(work.planningToStart)}</strong>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                Предварительная длительность{" "}
                <strong>
                  {msToHMS(
                    new Date(work.planningToFinish) -
                      new Date(work.planningToStart),
                  )}
                </strong>
              </Col>
            </Row>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  const attachmentsPopover = (
    <Popover style={{ maxWidth: "30rem" }}>
      <Popover.Header as="h3">Прикреплённые файлы</Popover.Header>
      <Popover.Body>
        {attachments?.map((a) => (
          <Row key={a.name} className="mb-2">
            <Col sm="12">
              <a
                href={`${process.env.REACT_APP_ADDRESS}/uploads/${a.name}`}
                target="_blank"
                rel="noreferrer"
              >
                {a.name}
              </a>
            </Col>
          </Row>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <ItemCard createdAt={createdAt} danger={isOverdue}>
      <Row>
        <Col sm={9}>
          <h4>
            <BrowserView>
              <Row>
                <Col sm="auto" as={NavLink} to={`/tickets/${num}`}>
                  <Badge className="mb-2" bg="primary">
                    {num}
                  </Badge>{" "}
                </Col>
                <Col sm="auto">
                  <Badge className="mb-2 w-100" bg="secondary">
                    <RiBuilding2Line /> {company?.alias}
                  </Badge>{" "}
                </Col>
                <Col sm="auto">{title}</Col>
              </Row>
            </BrowserView>
            <MobileView>
              <Row>
                <Col className="col-3" as={NavLink} to={`/tickets/${num}`}>
                  <Badge className="mb-2" bg="primary">
                    {num}
                  </Badge>{" "}
                </Col>
                <Col className="col-9">
                  <Badge className="mb-2 w-100" bg="secondary">
                    <RiBuilding2Line /> {company?.alias}
                  </Badge>{" "}
                </Col>
                <Col sm="auto">{title}</Col>
              </Row>
            </MobileView>
          </h4>
          <Row className="mb-2">
            <Col>
              Инициатор:{" "}
              <Badge
                bg="secondary"
                style={{
                  marginLeft: "0.5rem",
                }}
              >
                {applicant
                  ? applicant.lastName + " " + applicant.firstName
                  : realSender
                    ? realSender
                    : "неизвестен"}
              </Badge>
            </Col>
          </Row>
          {responsibles.length > 0 && (
            <Row className="mb-2">
              <Col>
                Ответственные:{" "}
                {responsibles?.map((user) => {
                  return (
                    <Badge
                      bg="secondary"
                      style={{
                        marginLeft: "0.5rem",
                      }}
                      key={user._id}
                    >
                      {user.lastName + " " + user.firstName}
                    </Badge>
                  );
                })}
              </Col>
            </Row>
          )}
          <Row className="d-flex justify-content-between">
            <Col className="text-muted">
              <div className="py-1">Создана: {formatDate(createdAt)}</div>
              <div className="pt-1">
                {state !== "Закрыта" && deadline && (
                  <> Дедлайн: {formatDate(deadline)}</>
                )}
                {state === "Закрыта" && <> Закрыта: {formatDate(finishedAt)}</>}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="text-end">
          <div className="h-100 d-flex flex-column justify-content-between">
            <div className="w-100">
              <h4 style={{ margin: "0" }}>
                <Badge className="my-1" bg={badgeBg}>
                  {state}
                </Badge>{" "}
                {isOverdue && !isClosed && (
                  <Badge className="my-1" bg="danger">
                    Просрочена
                  </Badge>
                )}
              </h4>
            </div>
            <div className="mt-1 flex-shrink-1">
              <OverlayTrigger
                placement="top"
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={scheduledWorksPopover}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginRight: "1rem" }}
                  disabled={scheduledWorks?.length === 0}
                >
                  <FaRegCalendar />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={attachmentsPopover}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginRight: "1rem" }}
                  disabled={attachments?.length === 0}
                >
                  <IoMdAttach />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={latestCommentPopover}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!latestComment}
                >
                  <FaRegComment />
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default TicketItem;
