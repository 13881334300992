import { Link } from "react-router-dom";

import DeleteServicePlan from "./Delete";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";

import ItemCard from "../../UI/ItemCard";

import { RiEdit2Line } from "react-icons/ri";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

import useOffcanvasStore from "../../store/offcanvas";

function ServicePlanItem({ servicePlan }) {
  const offcanvas = useOffcanvasStore();

  const tariffingTypes = [
    { name: "Фиксированная оплата", value: "fixedPrice" },
    { name: "Почасовая оплата", value: "hourly" },
    { name: "Пакеты часов", value: "hourPackage" },
  ];

  return (
    <ItemCard createdAt={servicePlan.createdAt}>
      <Row>
        <Col sm={9}>
          <Row>
            <Col sm="auto">
              <h4>{servicePlan.title}</h4>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              {
                tariffingTypes.filter(
                  (tariff) => servicePlan.tariffing?.type === tariff.value,
                )[0]?.name
              }
            </Col>
          </Row>
          {servicePlan.companies.length > 0 && (
            <Row className="py-2">
              <Col>
                Компании:{" "}
                {servicePlan.companies.map((company) => (
                  <Badge
                    bg="secondary"
                    key={company._id.toString()}
                    className="mx-2"
                  >
                    {company.alias}
                  </Badge>
                ))}
              </Col>
            </Row>
          )}
        </Col>
        <Col className="text-end">
          <div className="h-100 d-flex flex-column justify-content-between">
            <div></div>
            <div className="row mt-1 flex-shrink-1 justify-content-end align-items-end">
              <Col sm="auto">
                <Dropdown>
                  <Dropdown.Toggle>Действия</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={`${servicePlan._id}`}>
                      <HiOutlineMagnifyingGlass /> Просмотр
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`update/${servicePlan._id}`}
                      onClick={offcanvas.setShow}
                    >
                      <RiEdit2Line /> Изменить
                    </Dropdown.Item>
                    <DeleteServicePlan servicePlan={servicePlan} />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default ServicePlanItem;
