import { useState } from "react";
import { Form as RouterForm } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { RiDeleteBinLine } from "react-icons/ri";

const DeleteCompany = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" className="mb-2 w-100" onClick={handleShow}>
        <RiDeleteBinLine /> Удалить
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <RouterForm method="post">
          <Modal.Header closeButton>
            <Modal.Title>{props.company.alias}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы уверены? Все пользователи компании также будут удалены. Это
            действие нельзя отменить.
            <Form.Control
              name="id"
              type="text"
              hidden
              readOnly
              value={props.company._id}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Закрыть
            </Button>
            <Button variant="danger" type="submit" name="intent" value="delete">
              <RiDeleteBinLine /> Удалить
            </Button>
          </Modal.Footer>
        </RouterForm>
      </Modal>
    </>
  );
};

export default DeleteCompany;
