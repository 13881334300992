import { NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import ItemCard from "../../UI/ItemCard";

function CompanyItem({ company }) {
  const { _id, alias, createdAt } = company;
  return (
    <ItemCard createdAt={createdAt}>
      <Row>
        <Col xs="3" sm="1">
          <Image src="/companypic-placeholder.png" roundedCircle />
        </Col>
        <Col>
          <h4>
            <NavLink to={`${_id}`}>{alias}</NavLink>{" "}
          </h4>
          <p className="mb-0">
            {" "}
            <a href={company.linkToMap} target="_blank" rel="noreferrer">
              {company.address}
            </a>
          </p>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default CompanyItem;
