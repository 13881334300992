import { useState } from "react";

import Form from "react-bootstrap/Form";

import { useLoaderData } from "react-router-dom";
import FormWrapper from "../../UI/FormWrapper";

const TicketCategoryForm = (props) => {
  const data = useLoaderData();
  const [enteredTitle, setEnteredTitle] = useState(data ? data.title : "");
  const [isActive, setIsActive] = useState(data ? data.isActive : true);
  const [alwaysWithinPlan, setAlwaysWithinPlan] = useState(
    data ? data.alwaysWithinPlan : true,
  );

  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
  };

  const isActiveChangeHandler = () => {
    setIsActive(!isActive);
  };

  const alwaysWithinPlanChangeHandler = () => {
    setAlwaysWithinPlan(!alwaysWithinPlan);
  };

  return (
    <>
      <FormWrapper title={props.title} navigateTo="/ticket-categories">
        <Form.Group className="py-3 border-bottom">
          <Form.Label htmlFor="title">
            Наименование
            <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            autoFocus
            id="title"
            name="title"
            type="text"
            value={enteredTitle}
            onChange={titleChangeHandler}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            checked={alwaysWithinPlan}
            type="switch"
            id="alwaysWithinPlan"
            name="alwaysWithinPlan"
            label="Всегда в рамках тарифного плана"
            className="py-2"
            value={alwaysWithinPlan}
            onChange={alwaysWithinPlanChangeHandler}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            checked={isActive}
            type="switch"
            id="isActive"
            name="isActive"
            label="Активна"
            className="py-2"
            value={isActive}
            onChange={isActiveChangeHandler}
          />
        </Form.Group>
      </FormWrapper>
    </>
  );
};

export default TicketCategoryForm;
