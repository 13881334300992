import RoutineTaskItem from "./Item";

const RoutineTasksList = ({ items }) => {
  return (
    <>
      {items.map((task) => (
        <RoutineTaskItem key={task._id.toString()} task={task} />
      ))}
    </>
  );
};

export default RoutineTasksList;
