import { Outlet, useNavigation, Link, useNavigate } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";

import { RiRefreshLine } from "react-icons/ri";

import AddButton from "./AddButton";

import Transitions from "../animations/Transition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import Spinner from "../animations/Spinner";
import AlertMessage from "./AlertMessage";
import SearchBar from "./SearchBar";

import useOffcanvasStore from "../store/offcanvas";

const ListWrapper = ({
  title,
  isLoading,
  addRoute,
  filteredList = [],
  secondaryList = [],
  hideLengthRow = false,
  filterStore,
  onUpdatePage,
  children,
}) => {
  const { state } = useNavigation();
  const navigate = useNavigate();

  const offcanvas = useOffcanvasStore();

  const searchHandler = (e) => {
    const query = e.target.value;
    filterStore?.fullTextSearch(query);
  };

  return (
    <>
      <Card.Title>
        <h1 className="display-4">{title()}</h1>
      </Card.Title>
      <hr></hr>
      <BrowserView>
        <Row>
          <Col sm="auto">
            <Button onClick={onUpdatePage} size="lg" className="w-100 mb-3">
              <RiRefreshLine />
            </Button>
          </Col>
          <Col sm="auto">
            <AddButton
              onClick={offcanvas.setShow}
              content="Добавить"
              to={addRoute ? addRoute : "add"}
            />
          </Col>
          <Col>
            <SearchBar onChange={searchHandler} />
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row className="mb-3">
          <Col xs={6}>
            <Button as={Link} replace to="." size="lg" className="w-100">
              <RiRefreshLine /> Обновить
            </Button>
          </Col>
          <Col xs={6}>
            <AddButton content="Добавить" to={addRoute ? addRoute : "add"} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <SearchBar onChange={searchHandler} />
          </Col>
        </Row>
      </MobileView>
      {(filteredList.length > 0 || secondaryList.length > 0) && (
        <>
          {state === "idle" && !isLoading && (
            <Transitions>
              {!hideLengthRow && (
                <Row className="justify-content-between text-secondary mb-1 mt-3">
                  <Col>{`Найдено: ${filteredList.length}`}</Col>
                </Row>
              )}
              {children}
            </Transitions>
          )}
          {(state === "loading" || isLoading) && (
            <Transitions>
              <Spinner />
            </Transitions>
          )}
        </>
      )}
      {filteredList.length === 0 &&
        secondaryList.length === 0 &&
        !isLoading && <AlertMessage variant="light" message="Список пуст" />}
      <Offcanvas
        show={offcanvas.isActive}
        onHide={() => {
          navigate(-1);
          offcanvas.setClose();
        }}
        keyboard
        placement="bottom"
        className="h-100"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Outlet />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListWrapper;
