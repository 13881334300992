import { Link } from "react-router-dom";

import ItemCard from "../../UI/ItemCard";

import DeleteTicketCategory from "./Delete";
import Button from "react-bootstrap/Button";

import { RiEdit2Line } from "react-icons/ri";

import useOffcanvasStore from "../../store/offcanvas";

const TicketCategoryItem = ({ category }) => {
  const { createdAt, title, isActive, _id } = category;

  const offcanvas = useOffcanvasStore();

  return (
    <ItemCard createdAt={createdAt}>
      <h4>{title}</h4>
      <p>Активна: {isActive ? "да" : "нет"}</p>
      <Button as={Link} to={`update/${_id}`} onClick={offcanvas.setShow}>
        <RiEdit2Line /> Изменить
      </Button>
      <DeleteTicketCategory category={category} />
    </ItemCard>
  );
};

export default TicketCategoryItem;
