import { useState, useEffect } from "react";
import { useLoaderData, useLocation } from "react-router-dom";

import { MobileView, BrowserView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";

import { RiBuilding2Line } from "react-icons/ri";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";

import CompaniesList from "../../components/Company/List";
import CompanyFilter from "../../components/Company/Filter";

import ListWrapper from "../../UI/ListWrapper";

import useCompanyFilterStore from "../../store/filters/companies";
import useSidebarStore from "../../store/sidebar";

const Companies = () => {
  const location = useLocation();
  const { setLeftSidebarContent } = useSidebarStore();
  const { companies } = useLoaderData();
  const filterStore = useCompanyFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetch();
  }, [location]);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <CompanyFilter items={companies} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, companies]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <RiBuilding2Line /> Компании
      </>
    );
  };

  return (
    <ListWrapper
      title={title}
      filteredList={filterStore.filteredList}
      filterStore={filterStore}
      onUpdatePage={filterStore.fetch}
      secondaryList={companies}
      isLoading={filterStore.isLoading}
      hideLengthRow
      addRoute="/companies/add"
    >
      <MobileView>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
          <Offcanvas.Body>
            <CompanyFilter
              items={companies}
              setShowOffcanvas={setShowOffcanvas}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="justify-content-between text-secondary my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row className="justify-content-between text-secondary my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
        </Row>
      </BrowserView>
      <CompaniesList items={filterStore.filteredList}></CompaniesList>
    </ListWrapper>
  );
};

export default Companies;

export async function loader() {
  document.title = "КОМПАНИИ";

  const { token } = getLocalStorageData();

  const companiesResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/companies`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!companiesResponse.ok) {
    throw companiesResponse;
  }

  const responsiblesResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/users/responsibles`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!responsiblesResponse.ok) {
    throw responsiblesResponse;
  }

  return {
    companies: await companiesResponse.json(),
    responsibles: await responsiblesResponse.json(),
  };
}
