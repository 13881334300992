import { NavLink } from "react-router-dom";
import { isBrowser } from "react-device-detect";

import ItemCard from "../../UI/ItemCard";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";

function UserItem({ user }) {
  const {
    _id = "",
    firstName,
    lastName,
    company = {},
    position,
    createdAt = "",
    email,
    phone,
    role,
    isServiceAccount,
    isAdmin,
    isCloudTelephony,
  } = user;

  return (
    <ItemCard createdAt={createdAt}>
      <Row>
        {isBrowser && (
          <Col sm="2">
            <Image src="/profilepic-placeholder.jpg" roundedCircle />
          </Col>
        )}
        <Col>
          <h4 className="mb-3">
            <NavLink to={`${_id}`} className="me-3 my-1">
              {lastName} {firstName}
            </NavLink>
            <Badge className="bg-secondary my-1">{company.alias}</Badge>
          </h4>
          {position && <div className="py-1">{position}</div>}
          {email && (
            <div className="py-1">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
          {phone && (
            <div className="py-1">
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
        </Col>
        <Col sm="3" className="text-end">
          <Badge className="m-1">{role}</Badge>
          <Badge className="m-1">
            {isServiceAccount ? "Сервисный аккаунт" : ""}
          </Badge>
          <Badge className="m-1 bg-success">
            {isAdmin ? "Администратор" : ""}
          </Badge>
          <Badge className="m-1">{isCloudTelephony ? "Телефония" : ""}</Badge>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default UserItem;
